<template>
  <lazy-component class="contact">
    <div>
      <div class="contactMainDiv">
        <div class="contactMainContentDiv">
          <div v-if="isLoading" class="homeLoadingDiv">
            <img class="homeLoadingGifDiv"
            :src="'../images/logo.gif'" alt="" width="100px" height="100px" loop=infinite>
          </div>
          <div v-else>
          <div class="contactLargeText font-new">
            {{
              contactMainTitle == ""
                ? "Need help finding the perfect resort?"
                : contactMainTitle
            }}
          </div>
          <div class="contactSmallText font-new">
            {{
              contactSubTitle == ""
                ? "We’ll have one of our concierge member contact you personally."
                : contactSubTitle
            }}
          </div>
          <div class="contactNameInputGroupDiv">
            <div class="contactInputGroup">
              <div class="contactInputLabel font-new">First name</div>
              <a-input
                class="contactInput"
                v-model="firstName"
                placeholder=""
              />
            </div>
            <div class="contactLineSpace"></div>
            <div class="contactInputGroup">
              <div class="contactInputLabel font-new">Last name</div>
              <a-input class="contactInput" v-model="lastName" placeholder="" />
            </div>
          </div>
          <div class="contactInputGroup" style="display: flex">
            <div>
              <div class="contactInputLabel font-new">Email</div>
              <a-input
                class="contactInputEmail"
                v-model="email"
                placeholder=""
              />
            </div>
            <div style="margin-left: 20px; width: 300px; max-width: 300px">
              <div class="contactInputLabel font-new">Phone number</div>
              <div
                style="
                  display: flex;
                  height: 40px;
                  margin-top: 5px;
                  border-radius: 7px;
                  width: 300px;
                  max-width: 300px;
                "
              >
                <vue-country-code
                  @onSelect="onSelect"
                  :preferredCountries="['us', 'gb']"
                  style="
                    border: 0;
                    border-right: 1px solid white;
                    border-radius: 0;
                    border-top-left-radius: 7px;
                    border-bottom-left-radius: 7px;
                    background-color: rgba(255, 255, 255, 0.42);
                  "
                >
                </vue-country-code>
                <input
                  class="phone-number"
                  v-model="phoneNumber"
                  placeholder=""
                />
              </div>
            </div>
          </div>

          <div class="contactDateInputGroupDiv">
            <div class="contactInputGroup">
              <div class="contactInputLabel font-new">Date of travel</div>
              <a-date-picker
                class="contactDateInput"
                v-model="dateOfTravel"
                placeholder=""
                @change="onChange"
              />
            </div>
            <div class="contactLineSpace"></div>
            <div class="contactInputGroup">
              <div class="contactInputLabel font-new"># of Nights</div>
              <a-input-number
                class="contactDateInput"
                v-model="countNights"
                :min="0"
                :max="10000"
                placeholder=""
              />
            </div>
            <div class="contactLineSpace"></div>
            <div class="contactInputGroup">
              <div class="contactInputLabel font-new">Country of residence</div>

              <a-auto-complete
                class="contactDateInput"
                :data-source="countryList"
                placeholder=""
                v-model="country"
                @select="onSelectCountry"
                @blur="onBlurCountry"
                :filter-option="filterOption"
              />
            </div>
          </div>
          <div class="contactCountInputGroupDiv">
            <div class="contactInputGroup">
              <div class="contactInputLabel font-new"># of adults</div>
              <a-input-number
                class="contactAdultCountInput"
                v-model="countAdult"
                :min="0"
                :max="10000"
                placeholder=""
              />
            </div>
            <div class="contactLineSpace"></div>
            <div class="contactInputGroup">
              <div class="contactInputLabel font-new">
                # of childrens(under 12 years old)
              </div>
              <a-input-number
                class="contactAdultCountInput"
                v-model="countChild"
                :min="0"
                :max="10000"
                placeholder=""
              />
            </div>
          </div>
          <div class="contactInputGroup">
            <div class="contactInputLabel font-new">
              Nightly room budget (optional)
            </div>
            <a-input
              class="contactBudgetInput"
              v-model="roomBudget"
              placeholder=""
            />
          </div>
          <div class="contactCountInputGroupDiv">
            <div class="contactInputGroup text-align-center">
              <div class="contactInputLabel font-new">
                What is your preferred style of resort:
              </div>
              <div>
                <div @click="handleStyleresort('modern')">
                  <a-button
                    class="m-multichoice font-new"
                    v-if="
                      this.mordernluxuryresort == true &&
                      this.ecoluxuryresort == false
                    "
                    style="background: white; color: #40a9ff"
                  >
                    Modern Luxury Resort
                  </a-button>
                  <a-button class="m-multichoice font-new" v-else>
                    Modern Luxury Resort
                  </a-button>
                </div>
                <div @click="handleStyleresort('eco')">
                  <a-button
                    class="m-multichoice font-new"
                    v-if="
                      this.mordernluxuryresort == false &&
                      this.ecoluxuryresort == true
                    "
                    style="background: white; color: #40a9ff"
                  >
                    Eco-Luxury Resort
                  </a-button>
                  <a-button class="m-multichoice font-new" v-else>
                    Eco-Luxury Resort
                  </a-button>
                </div>
                <div @click="handleStyleresort('no')">
                  <a-button
                    class="m-multichoice font-new"
                    v-if="
                      this.mordernluxuryresort == true &&
                      this.ecoluxuryresort == true
                    "
                    style="background: white; color: #40a9ff"
                  >
                    No Preference
                  </a-button>
                  <a-button class="m-multichoice font-new" v-else>
                    No Preference
                  </a-button>
                </div>
              </div>
            </div>
            <div class="m-ml-100 contactInputGroup text-align-center">
              <div class="contactInputLabel font-new">
                What size resort do you prefer?
              </div>
              <div>
                <div @click="handleSizeresort('big')">
                  <a-button
                    class="m-multichoice font-new"
                    v-if="this.bigresort == true && this.smallresort == false"
                    style="background: white; color: #40a9ff"
                  >
                    Big resort with many facilties
                  </a-button>
                  <a-button class="m-multichoice font-new" v-else>
                    Big resort with many facilties
                  </a-button>
                </div>
                <div @click="handleSizeresort('small')">
                  <a-button
                    class="m-multichoice font-new"
                    v-if="this.bigresort == false && this.smallresort == true"
                    style="background: white; color: #40a9ff"
                  >
                    Small resort and more intimate
                  </a-button>
                  <a-button class="m-multichoice font-new" v-else>
                    Small resort and more intimate
                  </a-button>
                </div>
                <div @click="handleSizeresort('no')">
                  <a-button
                    class="m-multichoice font-new"
                    v-if="this.bigresort == true && this.smallresort == true"
                    style="background: white; color: #40a9ff"
                  >
                    No Preference
                  </a-button>
                  <a-button class="m-multichoice font-new" v-else>
                    No Preference
                  </a-button>
                </div>
              </div>
            </div>
          </div>

          <div class="contactInputGroup">
            <div class="contactInputLabel font-new">Message</div>
            <a-textarea
              v-model="message"
              class="contactMessageInput"
              placeholder=""
              :auto-size="{ minRows: 5, maxRows: 10 }"
            />
          </div>
          <div class="contactBtnSendDiv">
            <div class="contactBtnSend font-new" @click="onSendContact">
              SEND
            </div>
          </div>
        </div>
      </div>
      </div>

      <a-modal
        class="SignUpDiv"
        v-model="visibleSendSuccess"
        title=""
        :footer="null"
        :width="'auto'"
        :dialog-style="{
          'align-items': 'center',
          'max-width': '360px',
          top: '100px',
          width: '100%',
          height: '100%',
        }"
      >
        <div class="contactSendSuccessDiv">
          <div class="contactSendSuccessImgDiv">
            <img class="contactSendSuccessImg" :src="'/images/logo1.png'" />
            <div class="contactSendSuccessLogoTitle font-new">Collection</div>
          </div>
          <div class="contactSendSuccessContentDiv">
            <div class="contactSendSuccessContent font-new">
              Message has been sent. <br />
              Our concierge team will get back to you within 24 hours.
              <br /><br />
              Thank you.
            </div>
          </div>
        </div>
      </a-modal>
    </div>
  </lazy-component>
</template>
<script>
import { api } from "../helpers/Helpers";
import { common } from "../helpers/Common";
import validator from "validator";
import VueCountryCode from "vue-country-code";

const countryList = ["US", "RSS", "CH"];
export default {
  components: {
    VueCountryCode,
  },
  metaInfo: {
    // Children can override the title.
    title: "VUE Collection Contact",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site

    titleTemplate: "%s",

    //titleTemplate: '%s ← My maldives',
    // Define meta tags here.
    meta: [
      {
        name: "description",
        content:
          "Can’t decide on the perfect luxury resort in the Collection? We’re here to help. Contact VUE Collection and our dedicated concierge team will assist you in choosing the best resort for your dream vacation.",
      },
      //   {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      //   {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      //   {name: 'description', content: 'A curated collection of Collection resorts. Visited, reviewed and approved by Travel Experts.'},

      //   // OpenGraph data (Most widely used)
      //   {property: 'og:title', content: 'VUE Collection Contact'},
      //   {property: 'og:site_name', content: 'VUE Collection Contact'},
      //   // The list of types is available here: http://ogp.me/#types
      //   {property: 'og:type', content: 'website'},
      //   // Should the the same as your canonical link, see below.
      //   {property: 'og:url', content: 'https://vuemaldives.com/contact'},
      //   {property: 'og:image', content: 'https://vuemaldives.com/images/logo1.png'},
      //   // Often the same as your meta description, but not always.
      //   {property: 'og:description', content: 'A curated collection of Collection resorts. Visited, reviewed and approved by Travel Experts.'},

      //   // Twitter card
      //   {name: 'twitter:card', content: 'summary'},
      //   {name: 'twitter:site', content: 'https://vuemaldives.com/contact'},
      //   {name: 'twitter:title', content: 'VUE Collection Contact'},
      //   {name: 'twitter:description', content: 'A curated collection of Collection resorts. Visited, reviewed and approved by Travel Experts.'},
      //   // Your twitter handle, if you have one.
      //   {name: 'twitter:creator', content: '@alligatorio'},
      //   {name: 'twitter:image:src', content: 'https://vuemaldives.com/images/logo1.png'},

      //   // Google / Schema.org markup:
      //   {itemprop: 'name', content: 'VUE Collection Contact'},
      //   {itemprop: 'description', content: 'A curated collection of Collection resorts. Visited, reviewed and approved by Travel Experts.'},
      //   {itemprop: 'image', content: 'https://vuemaldives.com/images/logo1.png'}
    ],

    link: [{ rel: "canonical", href: "https://vuemaldives.com/contact" }],
  },
  data() {
    return {
      contactMainTitle: "",
      contactSubTitle: "",
      selectedBackgroundImage: "",

      firstName: "",
      lastName: "",
      email: "",
      dateOfTravel: "",
      country: "",
      countAdult: 0,
      countChild: 0,
      countNights: "",
      roomBudget: "",
      message: "",

      countryList,

      visibleSendSuccess: false,

      mordernluxuryresort: false,
      ecoluxuryresort: false,
      bigresort: false,
      smallresort: false,
      styleresort: "",
      sizeresort: "",
      phoneNumber: "",
      countryCode: "",
      isLoading: true,
    };
  },

  created() {
    this.getContactPageInfos();
    this.countryList = common.getCountryList();

    window.scrollTo(0, 0);
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  },
  methods: {
    handleStyleresort(key) {
      if (key == "modern") {
        this.mordernluxuryresort = true;
        this.ecoluxuryresort = false;
        this.styleresort = "Modern Luxury Resort";
      } else if (key == "eco") {
        this.mordernluxuryresort = false;
        this.ecoluxuryresort = true;
        this.styleresort = "Eco-Luxury Resort";
      } else {
        this.mordernluxuryresort = true;
        this.ecoluxuryresort = true;
        this.styleresort = "No Preference";
      }
      console.log(key);
    },
    handleSizeresort(key) {
      if (key == "big") {
        this.bigresort = true;
        this.smallresort = false;
        this.sizeresort = "Big resort with many facilities";
      } else if (key == "small") {
        this.bigresort = false;
        this.smallresort = true;
        this.sizeresort = "Small resort and more intimate";
      } else {
        this.bigresort = true;
        this.smallresort = true;
        this.sizeresort = "No Preference";
      }
    },

    // eslint-disable-next-line no-unused-vars
    onSelectCountry(value) {},
    onBlurCountry() {
      if (!this.countryList.includes(this.country)) this.country = "";
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      );
    },

    // eslint-disable-next-line no-unused-vars
    onChange(date, dateString) {},
    getContactPageInfos: async function () {
      const res = await api.getContactPageInfo();
      if (res != undefined && res.length > 0) {
        this.contactMainTitle = res[0].contactMainTitle;
        this.contactSubTitle = res[0].contactSubTitle;
        // this.selectedBackgroundImage = res[0].backgroundImage;
        this.selectedBackgroundImage = "/background/contact.png";
      }
    },
    onSendContact: async function () {
      if (this.firstName == "" || this.lastName == "") {
        this.$message.error("Please input first name and last name.");
        return;
      }
      if (this.email == "" || !validator.isEmail(this.email)) {
        this.$message.error("Please input valid email.");
        return;
      }
      if (this.dateOfTravel == "") {
        this.$message.error("Please input date of travel.");
        return;
      }
      if (this.country == "") {
        this.$message.error("Please input country.");
        return;
      }
      if (this.countAdult + this.countChild < 1) {
        this.$message.error("Please input # of adults and # of childrens.");
        return;
      }
      if (this.countNights == "") {
        this.$message.error("Please input # of nights.");
        return;
      }
      //   if (this.roomBudget == "") {
      //     this.$message.error("Please input Nightly room budget (optional).");
      //     return;
      //   }
      if (this.styleresort == "") {
        this.$message.error("Please select style of resort.");
        return;
      }
      if (this.sizeresort == "") {
        this.$message.error("Please select size of resort.");
        return;
      }
      //   if (this.message.length <= 0) {
      //     this.$message.error("Please input message.");
      //     return;
      //   }

      let _info = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        dateOfTravel: this.dateOfTravel,
        country: this.country,
        countAdult: this.countAdult,
        countChild: this.countChild,
        countNights: this.countNights,
        roomBudget: this.roomBudget,
        message: this.message,
        styleresort: this.styleresort,
        sizeresort: this.sizeresort,
      };
      const res = await api.sendContactInfo(_info);
      if (res.error == undefined) {
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.phoneNumber = "+" + this.countryCode;
        this.dateOfTravel = "";
        this.country = "";
        this.countAdult = "";
        this.countChild = "";
        this.countNights = "";
        this.roomBudget = "";
        this.message = "";
        this.visibleSendSuccess = true;
        this.sizeresort = "";
        this.styleresort = "";
        this.mordernluxuryresort = false;
        this.ecoluxuryresort = false;
        this.smallresort = false;
        this.bigresort = false;
        // this.$message.success('Send contact success.');
      }
    },
    onSelect({ name, iso2, dialCode }) {
      this.countryCode = dialCode;
      this.phoneNumber = "+" + dialCode + " ";
      console.log(name, iso2, dialCode);
    },
  },
};
</script>
